import React, { Fragment, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';

const people = [
  { id: 1, name: 'Wade Cooper' },
  { id: 2, name: 'Arlene Mccoy' },
  { id: 3, name: 'Devon Webb' },
  { id: 4, name: 'Tom Cook' },
  { id: 5, name: 'Tanya Fox' },
  { id: 6, name: 'Hellen Schmidt' },
];

export default function SelectBox({ name, id, value, onChange, button, children, buttonClass, options, placeHolder }) {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? options
      : options.filter((project) =>
          project.label.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
        );
  return (
    <Combobox value={value} onChange={onChange}>
      <div className="search-input-container" style={{ position: 'relative' }}>
        <div className="relative ">
          <Combobox.Input
            className={`${buttonClass}`}
            displayValue={(project) => {
              return value ? project.label : '';
            }}
            onChange={(event) => {
              if (event.target.value === '') {
                onChange(undefined);
              } else {
                setQuery(event.target.value);
              }
            }}
            placeholder={placeHolder ? placeHolder : 'Search Project'}
          />
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options
            style={{
              maxHeight: '240px',
              overflowY: 'auto',
              backgroundColor: '#FFF',
              position: 'absolute',
              boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
              zIndex: 130,
              width: '100%',
            }}
          >
            {filteredOptions.length === 0 && query !== '' ? (
              <div
                style={{
                  padding: '12px 16px',
                }}
              >
                Nothing found.
              </div>
            ) : (
              filteredOptions.map((project) => (
                <Combobox.Option key={project.value} className={({ active }) => `relative`} value={project}>
                  {({ selected, active }) => (
                    <>
                      <div className="select-options">
                        <div
                          style={{
                            padding: '12px 16px',
                            backgroundColor: `${selected ? '#FECB04' : ''}`,
                          }}
                        >
                          <div style={{ display: 'flex', gap: '15px', alignItems: 'center', cursor: 'pointer' }}>
                            {project.label}
                            {options.find((item) => item.value === project.value)?.flagCode ? (
                              <img
                                width={'32px'}
                                height={'24px'}
                                style={{ display: 'flex', marginTop: 'auto' }}
                                src={`https://flagcdn.com/h24/${
                                  options.find((item) => item.value === project.value)?.flagCode
                                }.png`}
                                alt={'img'}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
